/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Scrollコンポーネントは、スライダーを任意の位置までスクロールさせることができます。Dragコンポーネントがフリーモードの際に利用します。Moveコンポーネントと異なり、このコンポーネントはトランジションコンポーネントを経由せず、アニメーションフレームを利用してスクリプトで補間しながら移動させます。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "scroll",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#scroll",
    "aria-label": "scroll permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "scroll()")), "\n", React.createElement(Type, {
    code: 'scroll( position: number, duration?: number ): void'
  }), "\n", React.createElement(_components.p, null, "スライダーを指定の位置に向かってスクロールします。このメソッドは現在のインデックスなどを更新しませんので、基本的には", React.createElement(_components.code, null, "Controller#scroll()"), "を利用してください。"), "\n", React.createElement(Params, {
    params: [['destination', '目的地を指定'], ['duration', '省略可。スクロールにかける時間を指定。省略すると、距離から自動計算される']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "cancel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cancel",
    "aria-label": "cancel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "cancel()")), "\n", React.createElement(Type, {
    code: 'cancel(): void'
  }), "\n", React.createElement(_components.p, null, "スクロールを中断し、", React.createElement(_components.code, null, "scrolled"), "イベントを配信する。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
